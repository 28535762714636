import { time } from 'echarts';
import {
  createRouter,
  createWebHistory
} from 'vue-router'

const routes = [{
    path: '/',
    redirect: '/home',
  },
  {
    path: '/home',
    name: 'home',
    redirect: '/maintenance',
    meta: {
      index: 4,
      keepAlive: true
    },
    component: () => import('@/components/home')
  },
  {
    path: '/home_evimed',
    name: 'home_evimed',
    meta: {
      index: 4,
      keepAlive: true
    },
    component: () => import('@/components/home')
  },
  {
    path: '/search',
    name: 'search',
    meta: {
      index: 5,
      keepAlive: false
    },
    component: () => import('@/components/search')
  },
  {
    path: '/advanced-search',
    name: 'advanced-search',
    meta: {
      index: 6,
      keepAlive: false
    },
    component: () => import('@/components/advanced-search')
  },
  {
    path: '/report',
    name: 'report',
    meta: {
      index: 5,
      keepAlive: true
    },
    component: () => import('@/components/report')
  },
  {
    path: '/reportTransfer',
    name: 'reportTransfer',
    meta: {
      routerView: true,
    },
    component: () => import('@/components/report/transfer'),
    children:[
      {
        path: '/reportTransfer/decision-report-surper',
        name: 'reportTransfer/decisionReportSurper',
        component: () => import('@/components/decision-report/DecisionReportSurper')
      },
    ]
  },
  {
    path: '/decision-report-surper',
    name: 'decisionReportSurper',
    meta: {
      index: 6,
      keepAlive: false
    },
    component: () => import('@/components/decision-report/DecisionReportSurper')
  },
  {
    path: '/decision-report-xz',
    name: 'decisionReportXz',
    meta: {
      index: 6,
      keepAlive: false
    },
    component: () => import('@/components/decision-report/DecisionReportXz')
  },
  {
    path: '/decision-report-warn',
    name: 'decisionReportWarn',
    meta: {
      index: 6,
      keepAlive: false
    },
    component: () => import('@/components/decision-report/DecisionReportWarn')
  },
  {
    path: '/applicate',
    name: 'applicate',
    meta: {
      index: 5,
      keepAlive: false
    },
    component: () => import('@/components/applicate')
  },
  {
    path: '/aianalysishome',
    name: 'aianalysishome',
    meta: {
      index: 5,
      keepAlive: false
    },
    component: () => import('@/components/aianalysis/home.vue')
  },
  {
    path: '/aihistroy',
    name: 'aihistroy',
    meta: {
      index: 6,
      keepAlive: false
    },
    component: () => import('@/components/aianalysis/histroy.vue')
  },
  {
    path: '/safehome',
    name: 'safehome',
    meta: {
      index: 5,
      keepAlive: false
    },
    component: () => import('@/components/adverse-safe-analysis/home.vue')
  },
  {
    path: '/safehistroy',
    name: 'safehistroy',
    meta: {
      index: 6,
      keepAlive: false
    },
    component: () => import('@/components/adverse-safe-analysis/histroy.vue')
  },
  {
    path: '/safetransfer',
    name: 'safetransfer',
    meta: {
      routerView: true
    },
    component: () => import('@/components/adverse-safe-analysis/transfer.vue'),
    children:[
      {
        path: '/safetransfer/safedetail',
        name: 'safetransfer/safedetail',
        component: () => import('@/components/adverse-safe-analysis/detail.vue'),
      },
      {
        path: '/safetransfer/toadd',
        name: 'safetransfer/toadd',
        component: () => import('@/components/adverse-safe-analysis/toadd.vue'),
      },
    ]
  },
  {
    path: '/safedetail',
    name: 'safedetail',
    meta: {
      index: 7,
      keepAlive: false
    },
    component: () => import('@/components/adverse-safe-analysis/detail.vue')
  },
  {
    path: '/toadd',
    name: 'toadd',
    meta: {
      index: 6,
      keepAlive: false
    },
    component: () => import('@/components/adverse-safe-analysis/toadd.vue')
  },
  {
    path: '/aianalysisdetails',
    name: 'aianalysisdetails',
    meta: {
      index: 6,
      keepAlive: true
    },
    component: () => import('@/components/aianalysis/details.vue')
  },
  {
    path: '/aitest',
    name: 'aitest',
    meta: {
      index: 5,
      keepAlive: false
    },
    component: () => import('@/components/aitest')
  },
  {
    path: '/conclusion',
    name: 'conclusion',
    meta: {
      index: 5,
      keepAlive: false
    },
    component: () => import('@/components/conclusion')
  },
  {
    path: '/query',
    name: 'query',
    meta: {
      index: 5,
      keepAlive: false
    },
    component: () => import('@/components/query')
  },
  {
    path: '/category',
    name: 'category',
    meta: {
      index: 5,
      keepAlive: true
    },
    component: () => import('@/components/category'),
    beforeEnter: (to, from, next) => {
      if (from.path == '/search') {
        to.meta.status = 0;
      } else if (from.path == '/documents') {
        to.meta.status = 1;
      }
      next();
    }
  },
  {
    path: '/category_v',
    name: 'category_v',
    meta: {
      index: 5,
      keepAlive: false
    },
    component: () => import('@/components/category'),
    beforeEnter: (to, from, next) => {
      if (from.path == '/search') {
        to.meta.status = 0;
      } else if (from.path == '/documents') {
        to.meta.status = 1;
      }
      next();
    }
  },
  {
    path: '/guide',
    name: 'guide',
    meta: {
      index: 5,
      keepAlive: true
    },
    beforeEnter: (to, from, next) => {
      if(from.path=='/guidedetails'){
        to.meta.status=0;
      }else{
        to.meta.status=1;
      }
      next();
    },
    component: ()=>import('@/components/direction-search/guide')
  },
  {
    path: '/instruction',
    name: 'instruction',
    meta: {
      index: 5,
      keepAlive: true
    },
    component: () => import('@/components/direction-search/instruction/index.vue'),
  },
  {
    path: '/documents',
    name: 'documents',
    meta: {
      index: 6,
      keepAlive: false
    },
    component: () => import('@/components/details/documents')
  },
  {
    path: '/guidedetails',
    name: 'guidedetails',
    meta: {
      index: 6,
      keepAlive: true
    },
    component: () => import('@/components/details/guide')
  },
  {
    path: '/guidepdf',
    name: 'guidepdf',
    meta: {
      index: 7,
      keepAlive: false
    },
    component: () => import('@/components/details/guidepdf')
  },
  {
    path: '/instructdetails',
    name: 'instructdetails',
    meta: {
      index: 6,
      keepAlive: false
    },
    component: () => import('@/components/details/instruction')
  },
  {
    path: '/account',
    name: 'account',
    meta: {
      index: 1,
      keepAlive: true
    },
    component: () => import('@/components/login/account')
  },
  {
    path: '/vertifycode',
    name: 'vertifycode',
    meta: {
      index: 2,
      keepAlive: true
    },
    component: () => import('@/components/login/vertifycode')
  },
  {
    path: '/register',
    name: 'register',
    meta: {
      index: 3,
      keepAlive: true
    },
    component: () => import('@/components/login/register')
  },
  {
    path: '/findpassword',
    name: 'findpassword',
    meta: {
      index: 2,
      keepAlive: true
    },
    component: () => import('@/components/login/findpassword')
  },
  {
    path: '/repassword',
    name: 'repassword',
    meta: {
      index: 3,
      keepAlive: true
    },
    component: () => import('@/components/login/repassword')
  },
  {
    path: '/my',
    name: 'my',
    meta: {
      index: 4,
      keepAlive: false
    },
    component: () => import('@/components/my')
  },
  {
    path: '/set',
    name: 'set',
    meta: {

    },
    component: () => import('@/components/set')
  },
  {
    path: '/setInfo',
    name: 'setInfo',
    meta: {

    },
    component: () => import('@/components/set/setInfo'),

  },
  {
    path: '/setName',
    name: 'setName',
    meta: {},
    component: () => import('@/components/set/setInfo/setName')
  },
  {
    path: '/setSchool',
    name: 'setSchool',
    meta: {},
    component: () => import('@/components/set/setInfo/setSchool')
  },
  {
    path: '/setOffice',
    name: 'setOffice',
    meta: {},
    component: () => import('@/components/set/setInfo/setOffice')
  },
  {
    path: '/setTitle',
    name: 'setTitle',
    meta: {},
    component: () => import('@/components/set/setInfo/setTitle')
  },
  {
    path: '/uidSet',
    name: 'uidSet',
    meta: {

    },
    component: () => import('@/components/set/uidSet'),

  },
  {
    path: '/updateTel',
    name: 'updateTel',
    meta: {

    },
    component: () => import('@/components/set/uidSet/updateTel'),

  },
  {
    path: '/setEmail',
    name: 'setEmail',
    meta: {

    },
    component: () => import('@/components/set/uidSet/updateEmail/setEmail'),

  },
  {
    path: '/updateEmail',
    name: 'updateEmail',
    meta: {

    },
    component: () => import('@/components/set/uidSet/updateEmail'),

  },
  {
    path: '/newEmail',
    name: 'newEmail',
    meta: {

    },
    component: () => import('@/components/set/uidSet/updateEmail/newEmail'),

  },
  {
    path: '/updatePassword',
    name: 'updatePassword',
    meta: {

    },
    component: () => import('@/components/set/uidSet/updatePassword'),

  },
  {
    path: '/newNum',
    name: 'newNum',
    meta: {

    },
    component: () => import('@/components/set/uidSet/updateTel/newNum'),

  },
  {
    path: '/delUser',
    name: 'delUser',
    meta: {

    },
    component: () => import('@/components/set/uidSet/delUser'),

  },
  {
    path: '/about',
    name: 'about',
    meta: {

    },
    component: () => import('@/components/set/about'),
  },
  {
    path: '/empty',
    name: 'empty',
    meta: {

    },
    component: () => import('@/components/set/components/empty.vue'),
  },
  {
    path: '/vip',
    name: 'vip',
    meta: {
      keepAlive: false,
    },
    component: () => import('@/components/vip'),
  },
  {
    path: '/vipdetail',
    name: 'vipdetail',
    meta: {

    },
    component: () => import('@/components/vip/vipDetail'),
  },
  {
    path: '/freevip',
    name: 'freevip',
    meta: {

    },
    component: () => import('@/components/vip/components/freeVip.vue'),
  },
  {
    path: '/collection',
    name: 'collection',
    meta: {
      index: 5,
      keepAlive: false
    },
    component: () => import('@/components/collection'),
    beforeEnter: (to, from, next) => {
      if (from.path == '/documents') {
        to.meta.status = 0;
      } else if (from.path == '/guidedetails') {
        to.meta.status = 1;
      } else if (from.path == '/instructdetails') {
        to.meta.status = 2;
      }
      next();
    }
  },
  {
    path: '/subscription',
    name: 'subscription',
    meta: {
      index: 5,
      keepAlive: false
    },
    component: () => import('@/components/subscription'),
    beforeEnter: (to, from, next) => {
      if (from.path == '/documents') {
        to.meta.status = 0;
      } else if (from.path == '/authorDetail') {
        to.meta.status = 1;
      }
      next();
    }
  },
  {
    path: '/authorDetail',
    name: 'authorDetail',
    meta: {

    },
    component: () => import('@/components/subscription/author/detail'),
  },
  {
    path: '/subset',
    name: 'subset',
    meta: {

    },
    component: () => import('@/components/subscription/sub-set'),
  },
  {
    path: '/download',
    name: 'download',
    meta: {

    },
    component: () => import('@/components/download'),
  },
  {
    path: '/feedback',
    name: 'feedback',
    meta: {

    },
    component: () => import('@/components/feedback'),
  },
  {
    path: '/selTest',
    name: 'selTest',
    meta: {

    },
    component: () => import('@/components/selTest'),
  },
  {
    path: '/testsearch',
    name: 'testsearch',
    meta: {

    },
    component: () => import('@/components/selTest/search.vue'),
  },
  {
    path: '/testDetail',
    name: 'testDetail',
    meta: {
      keepAlive: true,
    },
    component: () => import('@/components/selTest/testDetail'),
  },
  {
    path: '/wallet',
    name: 'wallet',
    meta: {

    },
    component: () => import('@/components/wallet'),
  },
  {
    path: '/activity',
    name: 'activity',
    meta: {

    },
    component: () => import('@/components/wallet/pages/kmdh.vue'),
  },
  {
    path: '/bill',
    name: 'bill',
    meta: {

    },
    component: () => import('@/components/wallet/pages/bill.vue'),
  },
  {
    path: '/billDetail',
    name: 'billDetail',
    meta: {

    },
    component: () => import('@/components/wallet/pages/detail.vue'),
  },
  {
    path: '/recharge',
    name: 'recharge',
    meta: {
      keepAlive: true,
    },
    component: () => import('@/components/wallet/pages/recharge.vue'),
  },
  {
    path: '/order',
    name: 'order',
    meta: {

    },
    component: () => import('@/components/order'),
  },
  {
    path: '/orderDetail',
    name: 'orderDetail',
    meta: {

    },
    component: () => import('@/components/order/detail.vue'),
  },
  {
    path: '/signIn',
    name: 'signIn',
    meta: {

    },
    component: () => import('@/components/signIn/indexV2.vue'),
  },
  {
    path: '/signInV2',
    name: 'signInV2',
    meta: {

    },
    component: () => import('@/components/signIn/index.vue'),
  },
  {
    path: '/signIn-calendar',
    name: 'signInCalendar',
    meta: {

    },
    component: () => import('@/components/signIn/calendar.vue'),
  },
  {
    path: '/signIn-reward',
    name: 'signInReward',
    meta: {

    },
    component: () => import('@/components/signIn/rewardList.vue'),
  },
  {
    path: '/invitation',
    name: 'invitation',
    meta: {

    },
    component: () => import('@/components/signIn/activity/invitation.vue'),
  },
  {
    path: '/invitationCDK',
    name: 'invitationCDK',
    meta: {

    },
    component: () => import('@/components/signIn/activity/invitationCDK.vue'),
  },
  {
    path: '/invitationed',
    name: 'invitationed',
    meta: {

    },
    component: () => import('@/components/signIn/activity/invitationed.vue'),
  },
  {
    path: '/register_student',
    name: 'register_student',
    meta: {

    },
    component: () => import('@/components/signIn/activity/register_student.vue'),
  },
  {
    path: '/register_student1',
    name: 'register_student1',
    meta: {

    },
    component: () => import('@/components/signIn/activity/register_student1.vue'),
  },
  {
    path: '/register_doctor',
    name: 'register_doctor',
    meta: {

    },
    component: () => import('@/components/signIn/activity/register_teacher.vue'),
  },
  {
    path: '/success',
    name: 'success',
    meta: {

    },
    component: () => import('@/components/signIn/activity/success.vue'),
  },
  {
    path: '/success1',
    name: 'success1',
    meta: {

    },
    component: () => import('@/components/signIn/activity/success_code_stu1.vue'),
  },
  {
    path: '/success2',
    name: 'success2',
    meta: {

    },
    component: () => import('@/components/signIn/activity/success2.vue'),
  },
  {
    path: '/search-literature',
    name: 'search-literature',
    meta: {

    },
    component: () => import('@/components/category/pages/home.vue'),
  },
  {
    path: '/search-history',
    name: 'search-history',
    meta: {

    },
    component: () => import('@/components/category/pages/history.vue'),
  },
  {
    path: '/search-detail',
    name: 'search-detail',
    meta: {
      keepAlive: true
    },
    component: () => import('@/components/category/pages/detail.vue'),
  },
  {
    path: '/conference',
    name: 'conference',
    meta: {

    },
    component: () => import('@/components/signIn/activity/qkys_invitationed.vue'),
  },
  {
    path: '/ditui_conference',
    name: 'ditui_conference',
    meta: {

    },
    component: () => import('@/components/signIn/activity/kyzs_invitationed.vue'),
  },
  {
    path: '/success_code',
    name: 'success_code',
    meta: {

    },
    component: () => import('@/components/signIn/activity/success_code.vue'),
  },
  {
    path: '/success_code_ky',
    name: 'success_code_ky',
    meta: {

    },
    component: () => import('@/components/signIn/activity/success_code_ky.vue'),
  },
  {
    path: '/upactivity',
    name: 'upactivity',
    meta: {

    },
    component: () => import('@/components/signIn/activity/upactivity.vue'),
  },
  {
    path: '/upsuccess',
    name: 'upsuccess',
    meta: {

    },
    component: () => import('@/components/signIn/activity/up_success.vue'),
  },
  {
    path: '/taskInfo',
    name: 'taskInfo',
    meta: {
      keepAlive: true
    },
    component: () => import('@/components/yl/task/detail.vue'),
  },
  {
    path: '/taskInfo-admin',
    name: 'taskInfo-admin',
    meta: {
      keepAlive: false
    },
    component: () => import('@/components/yl/admin/detail.vue'),
  },
  {
    path: '/forum',
    name: 'forum',
    meta: {
      keepAlive: true
    },
    component: () => import('@/components/yl/forum/index.vue'),
  },
  {
    path: '/subject',
    name: 'subject',
    meta: {
      keepAlive: true
    },
    component: () => import('@/components/yl/forum/subject.vue'),
  },
  {
    path: '/subject-search',
    name: 'subject-search',
    meta: {

    },
    component: () => import('@/components/yl/forum/search.vue'),
  },
  {
    path: '/subject-result',
    name: 'subject-result',
    meta: {
      keepAlive: true
    },
    component: () => import('@/components/yl/forum/result.vue'),
    beforeEnter: (to, from, next) => {
      if (from.path == '/subject-search') {
        to.meta.status = 0;
      } else if (from.path == '/taskInfo') {
        to.meta.status = 1;
      }
      next();
    }
  },
  {
    path: '/subject-iframe',
    name: 'subject-iframe',
    meta: {

    },
    component: () => import('@/components/yl/task/iframe.vue'),
  },
  {
    path: '/demo',
    name: 'demo',
    meta: {

    },
    component: () => import('@/components/demo'),
  },
  {
    path: '/daily',
    name: 'daily',
    meta: {
      keepAlive: true
    },
    component: () => import('@/components/xiaoling-daily'),
  },
  {
    path: '/daily-list',
    name: 'daily-list',
    meta: {

    },
    component: () => import('@/components/xiaoling-daily/daily-paper'),
  },
  {
    path: '/more-list',
    name: 'more-list',
    meta: {

    },
    component: () => import('@/components/xiaoling-daily/more'),
  },
  {
    path: '/daily-search',
    name: 'daily-search',
    meta: {

    },
    component: () => import('@/components/xiaoling-daily/components/search'),
  }, {
    path: '/key-words',
    name: 'key-words',
    meta: {
      keepAlive: true
    },
    beforeEnter: (to, from, next) => {
      if (from.path == '/aianalysishome' || from.path == '/aihistroy') {
        to.meta.status = 0;
      } else if (from.path == '/aianalysisdetails') {
        to.meta.status = 1;
      }
      next();
    },
    component: () => import('@/components/aianalysis/components/key-words/key-words'),
  },
  // 药品遴选
  {
    path: '/drugs-evaluation',
    name: 'drugs-evaluation',
    meta: {},
    component: () => import('@/components/drugs-clinical-evaluation/index'),
  },
  // 历史记录
  {
    path: '/drugsclinicalhistroy',
    name: 'drugsclinicalhistroy',
    meta: {},
    component: () => import('@/components/drugs-clinical-evaluation/history.vue'),
  },
  // 选择价格
  {
    path: '/drugpriceinfo',
    name: 'drugpriceinfo',
    meta: {
      keepAlive: true,
      
    },
    component: () => import('@/components/drugs-clinical-evaluation/drug-price-info.vue'),
  },
  // 检测结果
  {
    path: '/druganalysis',
    name: 'druganalysis',
    meta: {
      keepAlive: true
    },
    component: () => import('@/components/drugs-clinical-evaluation/drug-analysis.vue'),
  },
  // 线上看板
  {
    path: '/returns',
    name: 'returns',
    meta: {},
    component: () => import('@/components/drugs-clinical-evaluation/drug-report.vue'),
  },
  // 分析页面国家和江苏省pdf量表
  {
    path: '/drugs-clinical-pdf',
    name: 'drugs-clinical-pdf',
    meta: {},
    component: () => import('@/components/drugs-clinical-evaluation/components/guidepdf.vue'),
  },
  {
    path: '/synonymPage',
    name: 'synonymPage',
    meta: {
      keepAlive: true
    },
    component: () => import('@/components/drugs-clinical-evaluation/synonymPage.vue'),
  },
  {
    path: '/drugList',
    name: 'drugList',
    meta: {
      keepAlive: true
    },
    component: () => import('@/components/drugs-clinical-evaluation/drugList/index.vue'),
  },
  {
    path: '/illnessList',
    name: 'illnessList',
    meta: {
      keepAlive: true
    },
    component: () => import('@/components/drugs-clinical-evaluation/illnessList'),
  },
  {
    path: '/pdfBox',
    name: 'pdfBox',
    meta: {},
    component: () => import('@/components/components/pdfBox'),
  },
  {
    path: '/referenceDrug',
    name: 'referenceDrug',
    meta: {
      keepAlive: true
    },
    component: () => import('@/components//drugs-clinical-evaluation/referenceDrug'),
  },
  {
    path: '/guideHome',
    name: 'guideHome',
    meta:{
    },
    component: () => import('@/components/category/pages/guideHome.vue'),
  },
  {
    path: '/guideHistory',
    name: 'guideHistory',
    meta:{
    },
    component: () => import('@/components/category/pages/guideHistory.vue'),
  },
  {
    path: '/guideCate',
    name: 'guideCate',
    meta:{
    },
    component: () => import('@/components/category/pages/guideCate.vue'),
  },
  {
    path: '/drugHome',
    name: 'drugHome',
    meta:{
    },
    component: () => import('@/components/category/pages/drugHome.vue'),
  },
  {
    path: '/drugCate',
    name: 'drugCate',
    meta:{
    },
    component: () => import('@/components/category/pages/drugCate.vue'),
  },
  {
    path: '/drugHistory',
    name: 'drugHistory',
    meta:{
      keepAlive: false
    },
    component: () => import('@/components/category/pages/drugHistory.vue'),
  },
  {
    path: '/yydy',
    name: 'yydy',
    component: () => import('@/components/yydy/index.vue'),
  },
  {
    path: '/manual',
    name: 'manual',
    meta:{
      keepAlive: false
    },
    component: () => import('@/components/manual/index.vue'),
  },
  {
    path: '/manualDetail',
    name: 'manualDetail',
    meta:{
      keepAlive: false
    },
    component: () => import('@/components/manual/components/index.vue'),
    children: [
      {
        path: '/manualDetail/01',
        name: 'manualDetail/01',
        component: () => import('@/components/manual/components/manual01.vue'),
      },
      {
        path: '/manualDetail/02',
        name: 'manualDetail/02',
        component: () => import('@/components/manual/components/manual02.vue'),
      },
      {
        path: '/manualDetail/03',
        name: 'manualDetail/03',
        component: () => import('@/components/manual/components/manual03.vue'),
      },
      {
        path: '/manualDetail/04',
        name: 'manualDetail/04',
        component: () => import('@/components/manual/components/manual04.vue'),
      },
      {
        path: '/manualDetail/05',
        name: 'manualDetail/05',
        component: () => import('@/components/manual/components/manual05.vue'),
      },
      {
        path: '/manualDetail/06',
        name: 'manualDetail/06',
        component: () => import('@/components/manual/components/manual06.vue'),
      },
      {
        path: '/manualDetail/07',
        name: 'manualDetail/07',
        component: () => import('@/components/manual/components/manual07.vue'),
      },
    ]
  },
  {
    path: '/wxInfo',
    name: 'wxInfo',
    meta:{
      keepAlive: false
    },
    component: () => import('@/components/yydy/info.vue'),
  },
  {
    path: '/transfer',
    name: 'transfer',
    meta:{
      keepAlive: false
    },
    component: () => import('@/components/yydy/transfer.vue'),
  },
  {
    path: '/policyDetail',
    name: 'policyDetail',
    meta:{
      keepAlive: false
    },
    component: () => import('@/components/details/policy.vue'),
  },
  {
    path: '/tracking',
    name: 'tracking',
    meta:{
      keepAlive: true,
    },
    component: () => import('@/components/tracking'),
  },
  {
    path: '/tracking-detail',
    name: 'tracking-detail',
    meta:{
      keepAlive: true
    },
    component: () => import('@/components/tracking/detail.vue'),
  },
  {
    path: '/tracking-share',
    name: 'tracking-share',
    meta:{
      keepAlive: false
    },
    component: () => import('@/components/tracking/share.vue'),
  },
  {
    path: '/gift-get',
    name: 'gift-get',
    meta:{
      keepAlive: false
    },
    component: () => import('@/components/vip/gift/get.vue'),
  },
  {
    path: '/createSubject',
    name: 'createSubject',
    meta:{
      keepAlive: false
    },
    component: () => import('@/components/yl/forum/create.vue'),
  },
  {
    path: '/task-audit',
    name: 'task-audit',
    meta:{
      keepAlive: false
    },
    component: () => import('@/components/yl/admin/audit.vue'),
  },
  {
    path: '/diary',
    name: 'diary',
    meta:{
      keepAlive: true
    },
    component: () => import('@/components/yl/forum/diary.vue'),
  },
  {
    path: '/maintenance',
    name: 'maintenance',
    meta:{
      keepAlive: false
    },
    component: () => import('@/components/demo/maintenance.vue'),
  },
  {
    path: '/aiOverview',
    name: 'aiOverview',
    meta:{
      keepAlive: true
    },
    component: () => import('@/components/aiOverview/index.vue'),
  },
  {
    path: '/aiProgramme',
    name: 'aiProgramme',
    meta:{
      keepAlive: true
    },
    component: () => import('@/components/aiProgramme/index.vue'),
  },
  {
    path: '/recharge-activities',
    name: 'rechargeActivities',
    meta:{
      keepAlive: false
    },
    component: () => import('@/components/signIn/activity/recharge01.vue'),
  },
  {
    path: '/clinicalHistory',
    name: 'clinicalHistory',
    meta:{
      keepAlive: false
    },
    component: () => import('@/components/category/pages/clinical/history.vue'),
  },
  {
    path: '/clinicalDetail',
    name: 'clinicalDetail',
    meta:{
      keepAlive: true
    },
    component: () => import('@/components/category/pages/clinical/detail.vue'),
  },
  {
    path: '/comperhensvie',
    name: 'comperhensvie',
    meta:{
      keepAlive: true,
      title:'综合搜索'
    },
    component: () => import('@/components/comperhensive'),
  },
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
export default router