import { getUserInfo } from '@/utils/my'
import { createStore } from 'vuex'

export default createStore({
  state: {
    selectArr:["搜文献","查指南","找药品","科研选题","药品安全性分析","超说明书用药","药品遴选"],
    userInfo:[],
    authorInfo:{},
    base64url:'',
    addList:[],
    slablearr:[],// 药品临床综合评价分析结果江苏省
    znlablearr:[],// 药品临床综合评价分析结果国家
    addillnessList:[],// 药品临床综合评价研究疾病清单自定义功能
    loadingNum:0,
    payShow: false, // 付费盒子
    payButShow:false, // 会员按键
    payText:'本服务需要消耗XX个灵豆',
    butText:'立即支付',
    payinfo:{},
    powerFlag: true,
    vipTip: {
      flag: false
    },
    statusHeight: '',
    keepAliveExclude: [],
    noLoading: false,
    reloadFlag: false,
    lastVisitedPath: '',
    payBox: 1,
    shareData: {},
    channelCode: '',
    rechargeWxTip: false
  },
  getters: {
    test(state){
      return state.selectArr
    }
  },
  mutations: {
    updateSelectArr(state,data){
      state.selectArr=[]
      Object.values(data).forEach((item,index)=>{
            item.arr.forEach((atem,andex)=>{
              if(atem.isDel){
                state.selectArr.push(atem.text)
              }
            })
      })
      localStorage.setItem('selectArr',state.selectArr)
    },
    updateUserInfo(state,data){
      if(data.functionInfo){
        data.functionInfoArr=data.functionInfo.map(v=>v.funcId);
        console.log(data.functionInfo.map(v=>v.funcId));
      }else{
        data.functionInfoArr=[];
      }
      if(data.vipInfo && data.vipInfo.length){
        data.vipallEndtime=data.vipInfo[0].vipEndTime;
      }
      state.userInfo=data;
      localStorage.setItem('user',JSON.stringify(state.userInfo))
    },
    authorInfo(state,data){
      state.authorInfo=data;
    },
    changepayShow(state,data){
      state.payShow=!state.payShow;
      if(data){
        console.log(data);
        state.payText=data.text;
        state.butText=data.butText;
        state.payinfo=data.payinfo;
        state.payBox=data;
      }
    },
    updateKeepExclude(state,data){
      if(data){
        state.keepAliveExclude=data;
      }
    },
   
  },
  actions: {
    async getUser({commit}){
      let res=await getUserInfo()
      if(res.code==200){
        commit('updateUserInfo', res.data);
        console.log(21);
      }
    }
  },
  modules: {
  }
})
